//AUTH USER ATTRIBUTES
export const SET_USER_ATTRIBUTES = "SET_USER_ATTRIBUTES";

//INDIVIDUAL ANALYISIS
export const SET_TRADE_DATA = "SET_TRADE_DATA";
export const SET_RESOLUTION_OR_TERM = "SET_RESOLUTION_OR_TERM";
export const CLEAN_TRADE_DATA = "CLEAN_TRADE_DATA";
export const LOADING_VOLATILITIES = "LOADING_VOLATILITIES";
export const LOADING_CHECKLISTS = "LOADING_CHECKLISTS";
export const LOADING_LOGIN = "LOADING_LOGIN";

//CORRELATION STUDY VARIABLES
export const INDIVIDUAL_PERFORMANCE = "INDIVIDUAL_PERFORMANCE";
export const LOADING_CORRELATION = "LOADING_CORRELATION";
export const WALLET_PERFORMANCE = "WALLET_PERFORMANCE";

//CORRELATION PRODUCTS VARIABLES
export const SET_CORRELATION_PRODUCTS = "SET_CORRELATION_PRODUCTS";
export const LOADING_CORRELATION_PRODUCTS = "LOADING_CORRELATION_PRODUCTS";

//DAILY REPORT VARIABLES
export const SET_DAILY_REPORT = "SET_DAILY_REPORT";
export const LOADING_DAILY_REPORT = "LOADING_DAILY_REPORT";
