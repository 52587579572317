import { SET_USER_ATTRIBUTES } from "../actions/types";

const userInfo = { userInfo: [{ Name: "profile", Value: "gb-admin" }] };

export default function (state = userInfo, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_USER_ATTRIBUTES:
      return {
        ...state,
        userInfo: payload,
      };
    default:
      return state;
  }
}
