import React, { Suspense } from "react";
import { Navigate } from "react-router-dom";

// Importación de Loading antes de las lazy imports
import Loading from "../components/loading/Loading";

const Header = React.lazy(() => import("../components/common/Header"));
const Footer = React.lazy(() => import("../components/common/Footer"));
const BreadcrumbD = React.lazy(() =>
  import("../components/common/BreadcrumbD")
);
const DailyGroupReport = React.lazy(() =>
  import("../components/dailyReport/DailyGroupReport")
);

export default function DailyGrouplReportPage() {
  //Auth and Loading variables
  const isAuthenticated = sessionStorage.getItem("token");

  // If not authenticated, redirect to Login
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <Suspense fallback={<Loading />}>
      <div className="page-container">
        <Header />
        <BreadcrumbD />
        <div className="content-wrap">
          <DailyGroupReport />
        </div>
        <Footer />
      </div>
    </Suspense>
  );
}
