import React, { Suspense } from "react";
import { Navigate } from "react-router-dom";

// Importación perezosa (lazy) de los componentes
const Header = React.lazy(() => import("../components/common/Header"));
const Footer = React.lazy(() => import("../components/common/Footer"));
const Breadcrum = React.lazy(() => import("../components/common/Breadcrum"));
const PositionRegister = React.lazy(() =>
  import("../components/positionRegister/PositionRegister")
);
import Loading from "../components/loading/Loading";

export default function PositionRegisterPage() {
  //Auth and Loading variables
  const isAuthenticated = sessionStorage.getItem("token");

  // If not authenticated, redirect to Login
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <Suspense fallback={<Loading />}>
      <Header />
      <Breadcrum
        individualAnalysis="show"
        summaryAnalysis="none"
        downloadReport="none"
      />
      <PositionRegister />
      <Footer />
    </Suspense>
  );
}
