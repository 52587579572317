import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaCalculator } from "react-icons/fa";

//Components
import CalculatorModal from "../common/modals/calculadoraPlazoEfectoModal";
import CustomTooltip from "../common/CustomTooltip";

//Images
import GlobxPinguino from "../../images/GlobxPinguino.png";
import FAQsIcon from "../../images/faqs.png";

// DATA FILES
import tooltips_text from "../../data/tooltips_text.json";

export default function Header() {
  const username = sessionStorage.getItem("username");
  const [isCalculatorOpen, setCalculatorOpen] = useState(false);

  const Logout = (e) => {
    e.preventDefault();
    window.location.href = `${process.env.BASE_URL}`;
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("code");
    sessionStorage.removeItem("codeExpire");
    sessionStorage.removeItem("username");
    sessionStorage.removeItem("id_token");
  };

  const toggleCalculator = () => {
    setCalculatorOpen(!isCalculatorOpen);
  };

  return (
    <>
      {/* ROW START*/}
      <div className="row m-0 align-items-center">
        {/* Logo Section 4 Columns*/}
        <div className="col-4 col-md-4 text-md-start my-2 my-md-0">
          <nav className="navbar navbar-light bg-white">
            <div>
              <img
                src={GlobxPinguino}
                className="img-fluid mx-auto d-block d-md-inline"
                style={{ width: "50%" }}
                alt="logo"
              />
              <h5
                className="text-md-start mt-2 mt-md-0"
                style={{ color: "#000000", fontWeight: "bold" }}
              >
                Options & Futures
              </h5>
            </div>
          </nav>
        </div>

        {/* Items section 8 Columns*/}
        <div className="col-8 col-md-8 text-end">
          <span
            style={{
              color: "#000000",
              fontWeight: "bold",
              marginRight: "2vh",
              fontSize: "1.5rem",
            }}
          >
            {username}
          </span>
          <CustomTooltip text={tooltips_text.calculadora_plazo_efecto} placement="left">
          <span>
          <FaCalculator
            onClick={toggleCalculator}
            style={{
              cursor: "pointer",
              fontSize: "1.5rem",
              color: "#050227",
              marginRight: "2vh",
            }}
          />
          </span>
          </CustomTooltip>
          <CustomTooltip text={tooltips_text.faqs} placement="left">
          <Link
            to="../faqs"
            style={{ textDecoration: "none", marginRight: "2vh" }}
          >
            <img
              src={FAQsIcon}
              alt="FAQs icon"
              style={{ width: "3%", height: "4vh" }}
            />
          </Link>
          </CustomTooltip>

          <button
            type="button"
            className="btn btn-secondary btn-md"
            style={{ background: "#050227" }}
            onClick={Logout}
          >
            LOGOUT
          </button>
        </div>
      </div>
      {/* ROW END*/}

      <div className="col-12 mt-2">
        <hr
          style={{
            height: "1px",
            backgroundColor: "#000000",
          }}
        />
      </div>

      {isCalculatorOpen && <CalculatorModal onClose={toggleCalculator} />}
    </>
  );
}
