import { WALLET_PERFORMANCE } from "../actions/types";

const initialState = {
  results: [
    {
      PORTAFOLIO: {
        "REND CARTERA ($)": 0,
        "DESVIACION ESTANDAR CARTERA (%)": 0,
        "RENDIMIENTO MAXIMO": 0,
        "RENDIMIENTO MINIMO": 0,
      },
    },
  ],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case WALLET_PERFORMANCE:
      return {
        ...state,
        results: payload,
      };
    default:
      return state;
  }
}
