import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export default function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">FINN</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Apartado en desarrollo</h4>
        <p>
          El siguiente apartado se encuentra en desarrollo, cuando se habilite
          se les notifocará y se les dará la capasitación correspondiente.
          Saludos!
        </p>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          Atentamente: <strong>Data Pulse Analytics</strong>
        </div>
        <Button onClick={props.onHide}>Cerrar</Button>
      </Modal.Footer>
    </Modal>
  );
}
