import React from "react";
import { Link } from "react-router-dom";

export default function Breadcrum(props) {
  const code = sessionStorage.getItem("code");
  return (
    <>
      <div className="col-12 col-md-6 mx-3 mt-3">
        {/* Breadcrumb para Individual Analysis */}
        <div style={{ display: props.individualAnalysis }}>
          <Link
            className="btn btn-md rounded-pill link-style"
            to={`/home/?code=${code}`}
          >
            Inicio
          </Link>
          <span className="link-cout d-none d-sm-inline">{" > "}</span>
          <Link
            className="btn btn-md rounded-pill link-style d-none d-sm-inline"
            to={`/individualAnalysis/?code=${code}`}
          >
            Opciones de Análisis
          </Link>
        </div>

        {/* Breadcrumb para Analysis Summary */}
        <div style={{ display: props.summaryAnalysis }}>
          <Link
            className="btn btn-md rounded-pill link-style"
            to={`/home/?code=${code}`}
          >
            Inicio
          </Link>
          <span className="link-cout d-none d-sm-inline">{" > "}</span>
          <Link
            className="btn btn-md rounded-pill link-style d-none d-sm-inline"
            to={`/individualAnalysis/?code=${code}`}
          >
            Opciones de Análisis
          </Link>
          <span className="link-cout d-none d-md-inline">{" > "}</span>
          <Link
            className="btn btn-md rounded-pill link-style d-none d-md-inline"
            to={`/analysisSummary/?code=${code}`}
          >
            Resumen de Análisis
          </Link>
        </div>
      </div>
    </>
  );
}
