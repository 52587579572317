import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

//Reducers
import auth from "./reducers/auth.js";
import analytics from "./reducers/analytics.js";
import loading from "./reducers/loading.js";
import individualPerformance from "./reducers/individualPerformance.js";
import correlationStudyNxn from "./reducers/correlationStudyNxn.js";
import resolution_or_term from "./reducers/resolution_or_term.js";
import dailyReport from "./reducers/dailyReport.js";
import walletPerformance from "./reducers/walletPerformance.js";

const initialState = {};
const middleware = [thunk];

const reducers = combineReducers({
  auth,
  analytics,
  loading,
  resolution_or_term,
  individualPerformance,
  correlationStudyNxn,
  dailyReport,
  walletPerformance,
});

const store = createStore(
  reducers,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
