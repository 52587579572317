import { SET_CORRELATION_PRODUCTS } from "../actions/types";

const initialState = {
  results: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_CORRELATION_PRODUCTS:
      return {
        ...state,
        results: payload,
      };
    default:
      return state;
  }
}
