import React from "react";
import { FaFacebook, FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <footer
        className="page-footer font-small blue"
        style={{
          backgroundColor: "#050227",
          height: "auto",
          borderTop: "10px solid grey",
        }}
      >
        <div className="row m-0 text-center text-white py-3">
          {/* Left Column: Developed By */}
          <div className="col-12 col-md-4 my-auto">
            <h6>Desarrollado por</h6>
            <h6>Data Pulse Analytics</h6>
            <hr style={{ height: "0.3vh", margin: "auto", width: "50%" }} />
          </div>

          {/* Middle Column: Rights Reserved */}
          <div className="col-12 col-md-4 my-auto">
            <h6>Derechos reservados</h6>
            <h6>GlobX Options & Futures</h6>
            <hr style={{ height: "0.3vh", margin: "auto", width: "50%" }} />
          </div>

          {/* Right Column: Social Links */}
          <div className="col-12 col-md-4 my-auto">
            <Link
              to="https://www.facebook.com/GlobxOF"
              className="align-items-center d-flex justify-content-center"
              style={{ textDecoration: "none" }}
            >
              <FaFacebook /> &nbsp; Facebook
            </Link>

            <Link
              to="https://www.linkedin.com/company/globx-options-&-futures"
              style={{ textDecoration: "none" }}
              className="align-items-center d-flex justify-content-center"
            >
              <FaLinkedin /> &nbsp; Linkedin
            </Link>

            <h6>CopyRight@2023</h6>
            <hr style={{ height: "0.3vh", margin: "auto", width: "50%" }} />
          </div>
        </div>
      </footer>
    </>
  );
}
