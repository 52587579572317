import axios from "axios";
import { SET_CORRELATION_PRODUCTS } from "./types";
import { loadingCorrelationProducts } from "./loading";

//Get correlation Products values
export const setCorrelationProducts = (data) => (dispatch) => {
  //Get Id Token & Username
  const id_token = sessionStorage.getItem("id_token");
  const username = sessionStorage.getItem("username");
  axios
    .post("/api/correlation_products", data, {
      headers: {
        Authorization: `Bearer ${id_token}`,
        Username: username,
      },
    })
    .then(dispatch(loadingCorrelationProducts(true)))
    .then((response) => {
      dispatch({
        type: SET_CORRELATION_PRODUCTS,
        payload: response.data,
      });
      dispatch(loadingCorrelationProducts(false));
      //DIEGO CAMBIAR ESTO POR EL MODAL DE SUCCESS
      alert("Correlación calculada con éxito");
    })
    .catch((e) => {
      dispatch(loadingCorrelationProducts(false));
      //DIEGO CAMBIAR ESTO POR EL MODAL DE ERROR
      alert("Error en el calculo de Correlación");
    });
};
