import { SET_DAILY_REPORT } from "../actions/types";

const initialState = {
  data: {
    term: null,
    bar_res: null,
    product: "",
    selected_volatility: 25,
    time_input: "",
    product_time_operation: 0
  },
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_DAILY_REPORT:
      return {
        ...state,
        data: payload,
      };
    default:
      return state;
  }
}
