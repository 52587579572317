import React, { Suspense } from "react";
import { Navigate } from "react-router-dom";

// Importación perezosa (lazy) de los componentes
const Header = React.lazy(() => import("../components/common/Header"));
const Footer = React.lazy(() => import("../components/common/Footer"));
const BreadcrumbH = React.lazy(() =>
  import("../components/common/BreadcrumbH")
);
const HistoricTable = React.lazy(() =>
  import("../components/historicTable/HistoricTable")
);
import Loading from "../components/loading/Loading";

//TODO Review if this page works properly or not
export default function HistoricTablePage() {
  //Auth and Loading variables
  const isAuthenticated = sessionStorage.getItem("token");

  // If not authenticated, redirect to Login
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <Suspense fallback={<Loading />}>
      <div id="historic_page_container">
        <Header />
        <BreadcrumbH />
        <HistoricTable />
        <Footer />
      </div>
    </Suspense>
  );
}
