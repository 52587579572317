import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";

//Import Pages
import HomePage from "./pages/HomePage";
import IndividualAnalysisPage from "./pages/IndividualAnalysisPage";
import AnalysisSummaryPage from "./pages/AnalysisSummaryPage";
import HistoricTablePage from "./pages/HistoricTablePage";
import GroupAnalysisPage from "./pages/GroupAnalysisPaje";
import DailyReportPage from "./pages/DailyReportPage";
import CorrelationStudyPage from "./pages/CorrelationStudyPage";
import PositionRegisterPage from "./pages/PositionRegisterPage";
import DailyIndividualReportPage from "./pages/DailyIndividualReportPage";
import DailyGroupReportPage from "./pages/DailyGrouplReportPage";
import AnalysisPage from "./pages/AnalysisPage";
import FaqsPage from "./pages/FaqsPage";
//Redirect login to Cognito Hosted UI
const RedirectToCognito = () => {
  useEffect(() => {
    window.location.href = `${process.env.BASE_URL}/api/cognito/login`;
  }, []);
  return null;
};

export default function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<RedirectToCognito />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/analysisSummary" element={<AnalysisSummaryPage />} />
          <Route
            path="/individualAnalysis"
            element={<IndividualAnalysisPage />}
          />
          <Route path="/historicTable" element={<HistoricTablePage />} />
          <Route path="/groupAnalysis" element={<GroupAnalysisPage />} />
          <Route path="/dailyReport" element={<DailyReportPage />} />
          <Route path="/correlationStudy" element={<CorrelationStudyPage />} />
          <Route path="/positionRegister" element={<PositionRegisterPage />} />
          <Route
            path="/dailyIndividualReport"
            element={<DailyIndividualReportPage />}
          />
          <Route path="/dailyGroupReport" element={<DailyGroupReportPage />} />
          <Route path="/analysis" element={<AnalysisPage />} />
          <Route path="/faqs" element={<FaqsPage />} />
        </Routes>
      </Router>
    </>
  );
}
