import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";

import Trading1 from "../../images/Trading_home_1.jpg";
import Trading2 from "../../images/Trading_home_2.png";

import MyVerticallyCenteredModal from "../common/modals/confirmationModal";

//GIFS
import GIF from "../common/Gif";
import phrases_list from "../../data/phrases.json";
import recuerda_gif from "../../gifs/RECUERDA_GIFF_VIDEO.gif";
import sabias_gif from "../../gifs/SABIAS-QUE_GIFF_VIDEO.gif";

export default function analysis() {
  //Get code
  const code = sessionStorage.getItem("code");

  // GIF Phrases
  const [randomPhrase, setRandomPhrase] = useState("");
  const [showRecuerda, setShowRecuerda] = useState(true);
  const [showSabias, setShowSabias] = useState(false);

  // GIF Recuerda Timer
  useEffect(() => {
    if (showRecuerda) {
      const randomIndex = Math.floor(
        Math.random() * phrases_list.recuerda_phrases.length
      );
      setRandomPhrase(phrases_list.recuerda_phrases[randomIndex]);

      const recuerdaTimer = setTimeout(() => {
        setShowRecuerda(false);
        setTimeout(() => {
          setShowSabias(true);
        }, 120000); // 2 minutes delay after recuerda disappears (2 * 60 * 1000)
      }, 18000); // Recuerda message appears for 18 seconds

      return () => clearTimeout(recuerdaTimer);
    }
  }, [showRecuerda]);

  // GIF Sabias Timer
  useEffect(() => {
    if (showSabias) {
      const randomIndex = Math.floor(
        Math.random() * phrases_list.sabias_phrases.length
      );
      setRandomPhrase(phrases_list.sabias_phrases[randomIndex]);

      const sabiasTimer = setTimeout(() => {
        setShowSabias(false);
        setTimeout(() => {
          const showNext =
            Math.random() < 0.5 ? setShowRecuerda(true) : setShowSabias(true);
        }, 120000); // 2 minutes delay after recuerda disappears (2 * 60 * 1000)
      }, 18000); // Recuerda message appears for 18 seconds

      return () => clearTimeout(sabiasTimer);
    }
  }, [showSabias]);

  const navigate = useNavigate();
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <div className="text-center mt-5 mb-5">
        <h1 style={{ fontWeight: "bold" }}>Tipo de Checklist</h1>
      </div>

      <div className="container mt-5">
        <div className="row justify-content-center">
          {/* Checklist Individual Card */}
          <div className="col-12 col-md-5 mb-4">
            <Card
              onClick={() => navigate(`/individualAnalysis/?code=${code}`)}
              style={{ cursor: "pointer" }}
            >
              <Card.Img height={250} variant="top" src={Trading1} />
              <Card.Body>
                <Card.Title className="text-center">
                  Checklist Individual
                </Card.Title>
                <Card.Text>
                  Esta herramienta se utiliza para evaluar la tendencia o trade
                  que se desea realizar. Analiza detalladamente todos los
                  indicadores y asigna una calificación. Si la calificación es
                  favorable, significa que la tendencia es adecuada.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>

          {/* Checklist Grupal Card */}
          <div className="col-12 col-md-5 mb-4">
            <Card
              onClick={() => navigate(`/groupAnalysis/?code=${code}`)}
              style={{ cursor: "pointer" }}
            >
              <Card.Img height={250} variant="top" src={Trading2} />
              <Card.Body>
                <Card.Title className="text-center">
                  Checklist Grupal
                </Card.Title>
                <Card.Text>
                  Se utiliza para evaluar varios productos simultáneamente, con
                  un máximo de 8 productos a la vez. Esto te ayudará a evitar
                  analizar producto por producto y a ser más eficiente en tus
                  tiempos de operación.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>

      {/* Modal and Notifications */}
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      {showRecuerda && (
        <div className="gif-notification-box">
          <button
            onClick={() => setShowRecuerda(false)}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              background: "transparent",
              border: "none",
              fontSize: "18px",
              cursor: "pointer",
            }}
          >
            &times;
          </button>
          <GIF gif={recuerda_gif} width={"150px"} />
          <p style={{ fontSize: "14px", color: "#333", margin: 0 }}>
            {randomPhrase}
          </p>
        </div>
      )}

      {showSabias && (
        <div className="gif-notification-box">
          <button
            onClick={() => setShowSabias(false)}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              background: "transparent",
              border: "none",
              fontSize: "18px",
              cursor: "pointer",
            }}
          >
            &times;
          </button>
          <GIF gif={sabias_gif} width={"150px"} />
          <p style={{ fontSize: "14px", color: "#333", margin: 0 }}>
            {randomPhrase}
          </p>
        </div>
      )}
    </>
  );
}
