import React, { Suspense } from "react";
import { Navigate } from "react-router-dom";

import Footer from "../components/common/Footer";
import Header from "../components/common/Header";
import BreadcrumbF from "../components/common/BreadcrumbF";
import Faqs from "../components/faqs/Faqs";
import Loading from "../components/loading/Loading";

export default function FaqsPage() {
  //Auth and Loading variables
  const isAuthenticated = sessionStorage.getItem("token");

  // If is not Athenticate redirect to Login
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <Suspense fallback={<Loading />}>
      <div className="page-container">
        <Header />
        <BreadcrumbF />
        <div className="content-wrap">
          <Faqs />
        </div>
        <Footer />
      </div>
    </Suspense>
  );
}
