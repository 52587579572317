import { SET_RESOLUTION_OR_TERM } from "../actions/types";

const initialState = {
  info: {
    "X/2": 0,
    X: 0,
    "3X": 0,
  },
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_RESOLUTION_OR_TERM:
      return {
        ...state,
        info: payload,
      };
    default:
      return state;
  }
}
