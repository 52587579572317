import React from "react";

function GIF(props) {
  return (
    <>
      <img
        src={props.gif}
        style={{ width: props.width, height: props.height }}
      />
    </>
  );
}

export default GIF;
