// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    width: 100%;
}

thead {
  color: white;
  background-color: #001F3F;
  font-size: 80%;
}

tbody, tfoot {
  font-size: 80%;
}

table {
  border-color: black !important;
}

.td-total{
  color: white;
  font-weight: bold;
  background-color: #001F3F !important;
}`, "",{"version":3,"sources":["webpack://./../globx/frontend/src/components/correlationStudy/CorrelationStudy.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,oCAAoC;AACtC","sourcesContent":[".container {\n    width: 100%;\n}\n\nthead {\n  color: white;\n  background-color: #001F3F;\n  font-size: 80%;\n}\n\ntbody, tfoot {\n  font-size: 80%;\n}\n\ntable {\n  border-color: black !important;\n}\n\n.td-total{\n  color: white;\n  font-weight: bold;\n  background-color: #001F3F !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
