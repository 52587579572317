// React & General Libraries
import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Bootstrap and custom CSS
//import "bootstrap/dist/css/bootstrap.min.css";
import "./Faqs.css";
import faqs from "../../data/faqs.json";
// Main component for the FAQ page
export default function Faqs() {
  const [openGenerales, setopenGenerales] = useState(false);
  const [checklist, setchecklist] = useState(false);
  const [dailyreport, setdailyreport] = useState(false);
  const [activeSubButton, setActiveSubButton] = useState(null);

  const toggleGenerales = () => setopenGenerales(!openGenerales);
  const toggleChecklist = () => setchecklist(!checklist);
  const toggleDailyReport = () => setdailyreport(!dailyreport);

  const toggleSubButton = (index) => {
    setActiveSubButton(activeSubButton === index ? null : index);
  };

  const renderGeneralesSection = (sectionTitle, toggleFunction, isOpen, questions) => (
    <>
      <Button
        onClick={toggleFunction}
        aria-controls={`collapse-${sectionTitle}`}
        aria-expanded={isOpen}
        variant="primary"
        className="w-100 mb-3"
        style={{
          backgroundColor: "#050227",
          borderColor: "#050227",
          color: "white",
          fontWeight: 500,
          letterSpacing: "0.1em",
        }}
      >
        {sectionTitle}
      </Button>

      <Collapse in={isOpen}>
        <div id={`collapse-${sectionTitle}`} className="collapse">
          <Row>
            {questions.map((question, index) => (
              <Col xs={12} md={6} key={index} className="mb-3">
                <Button
                  onClick={() => toggleSubButton(index + sectionTitle)}
                  aria-controls={`collapse-text-${index + sectionTitle}`}
                  aria-expanded={activeSubButton === index + sectionTitle}
                  variant="secondary"
                  className="w-100"
                  style={{
                    backgroundColor: "#25274d",
                    fontWeight: 500,
                  }}
                >
                  {question.question}
                </Button>
                <Collapse in={activeSubButton === index + sectionTitle}>
                  <Card
                    body
                    id={`collapse-text-${index + sectionTitle}`}
                    className="mt-2"
                  >
                    {question.answer}
                  </Card>
                </Collapse>
              </Col>
            ))}
          </Row>
        </div>
      </Collapse>
    </>
  );

  return (
    <Container className="my-4">
      <h2 className="text-center" style={{ fontWeight: "bold" }}>
        FAQS
      </h2>

      {renderGeneralesSection("Generales", toggleGenerales, openGenerales, faqs.Generales)}
      {renderGeneralesSection("Checklist", toggleChecklist, checklist, faqs.Checklist)}
      {renderGeneralesSection("Reporte Diario", toggleDailyReport, dailyreport, faqs.Reporte_Diario)}
    </Container>
  );
}