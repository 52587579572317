// React & General Libraries
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "jspdf-autotable";

// Bootstrap
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";

// Ant Design Components
import { Select, Input } from "antd";

// Actions
import { walletPerformance } from "../../actions/walletPerformance";
import { individualPerformance } from "../../actions/individualPerformance";

// Time & Products Data (JSON)
import time_days from "../../data/time_days.json";
import products_data from "../../data/products.json";
import tooltips_text from "../../data/tooltips_text.json";
// COMMOM COMPONENTS
import CustomTooltip from "../common/CustomTooltip";

export default function WalletPerformanceTables() {
  // Redux Dispatch Hook
  const dispatch = useDispatch();

  // React State Variables

  const [open, setOpen] = useState(false);
  const [termd, setTermd] = useState(0);
  const [objective_product_value, setObjetiveProductValue] = useState();
  const [coverageProductList, setCoverageProductList] = useState([]);
  const [coberProducts, setCoberProducts] = useState([]);
  const [objective_investment_percentage, setObjectiveInvestmentPercentage] =
    useState("");
  const [investmentAmount, setInvestmentAmount] = useState("");
  const [percentages, setPercentages] = useState([]);
  const [totalPercentage, setTotalPercentage] = useState(0);
  const [wallet_performance_list, setWalletPerformanceList] = useState([]);
  const [showImage, setShowImage] = useState(false);

  // Redux Selectors
  const portfolio = useSelector(
    (state) => state.individualPerformance.results[0]
  );
  const wallet_performance = useSelector(
    (state) => state.walletPerformance.results[0]
  );
  const wallet_performance_img = useSelector(
    (state) => state.walletPerformance.results[1]
  );

  // Decode base64 image for display
  const decodedImage = `data:image/png;base64, ${wallet_performance_img}`;

  // Custom Variables
  const term = `${termd}d`;
  const termsd = time_days.time;
  const products = products_data.products;
  let list_products = [];

  // Populate portfolio product list
  for (let product in portfolio) {
    list_products.push(portfolio[product]["PORTAFOLIO"]);
  }

  // Add percentage and type to product data if there are multiple products
  if (list_products.length > 1) {
    for (let i = 0; i < list_products.length; i++) {
      list_products[i]["PERCENTAGE"] = percentages[i];
      list_products[i]["TYPE"] = coberProducts[i]["type"];
    }
  }

  // Handle form submission for fetching individual and wallet performance
  const getwalletPerformance = (e) => {
    e.preventDefault();

    const individual_performance_data = {
      objective_product_objective: objective_product_value,
      coverage_product_list: coverageProductList,
      term: term,
      investment: investmentAmount,
      percentage_list: percentages,
    };

    const wallet_performance_data = {
      product_list: wallet_performance_list,
      percentage_list: percentages,
      term: term,
      investment: investmentAmount,
    };

    // Dispatch actions to fetch performance data
    dispatch(individualPerformance(individual_performance_data));
    dispatch(walletPerformance(wallet_performance_data));

    // Show the performance image after the data is fetched
    setShowImage(true);
  };

  // Handle term selection from dropdown
  const handleTermsd = (value) => {
    const selectedTerm = termsd.find((e) => e.value === value);
    if (selectedTerm) {
      setTermd(selectedTerm.value);
    }
  };

  const handlePorcentageChange = (e, index) => {
    const percentage = [...percentages];
    percentage[index] = Number(e.target.value);
    setPercentages(percentage);
  };

  // Handle objective product selection
  const handleObjetiveProduct = (value) => {
    const selectedProduct = products.find((e) => e.value === value);
    if (selectedProduct) {
      setObjetiveProductValue(selectedProduct.value);
      setCoberProducts([
        {
          product: selectedProduct.value,
          percentage: Number(objective_investment_percentage),
          type: "Principal",
        },
      ]);

      updateWalletPerformanceList(selectedProduct.value, coverageProductList);
    }
  };

  // Handle coverage product selection
  const handleCoverageProducts = (values) => {
    if (values.length <= 5) {
      const product_data = [coberProducts[0]];

      const selectedProducts = values
        .map((value) => {
          const product = products.find((e) => e.value === value);
          return product ? product.value : null;
        })
        .filter((value) => value !== null);

      for (const value of selectedProducts) {
        product_data.push({
          product: value,
          type: "Adicionales",
        });
      }

      const removedItems = coverageProductList.filter(
        (item) => !selectedProducts.includes(item)
      );

      if (removedItems.length > 0) {
        const removedItemIndex = coverageProductList.findIndex(
          (item) => item === removedItems[0]
        );

        const percentage_data = [];
        for (let i = 0; i < percentages.length; i++) {
          if (i !== removedItemIndex + 1) {
            percentage_data.push(percentages[i]);
          }
        }
        setPercentages(percentage_data);
      } else {
        setPercentages([...percentages]);
      }

      setCoberProducts(product_data);
      setCoverageProductList(selectedProducts);

      updateWalletPerformanceList(objective_product_value, selectedProducts);
    } else {
      alert("You cannot select more than 5 products.");
    }
  };

  // Update wallet performance list with objective and coverage products
  const updateWalletPerformanceList = (
    objectiveProductValue,
    coverageProductValues
  ) => {
    const combinedList = [objectiveProductValue, ...coverageProductValues];
    setWalletPerformanceList(combinedList);
  };

  // Handle objective investment percentage input change
  const handleInvestmentPercentage = (e) => {
    const percentageValue = Number(e.target.value);
    setObjectiveInvestmentPercentage(percentageValue);
    const updatedPercentages = [...percentages];
    updatedPercentages[0] = percentageValue;
    setPercentages(updatedPercentages);
    setCoberProducts(coberProducts);
  };

  // Calculate the total percentage whenever percentages change
  useEffect(() => {
    const total = percentages.reduce((total, value) => total + value, 0);
    setTotalPercentage(total);
  }, [percentages]);

  // Determine if the submit button should be disabled
  const isButtonDisabled = !(
    coverageProductList.length > 0 &&
    coberProducts.length > 0 &&
    objective_investment_percentage &&
    investmentAmount &&
    percentages.length > 0 &&
    totalPercentage === 100
  );
  //Return JSX
  return (
    <>
      <Container className="my-4">
        <div className="text-center mb-5">
          <Button
            onClick={() => setOpen(!open)}
            aria-controls="collapse-content"
            aria-expanded={open}
            className="mb-3 w-100"
            style={{
              backgroundColor: "#050227",
              borderColor: "#050227",
              color: "white",
              fontWeight: 500,
              letterSpacing: "0.1em",
            }}
          >
            Mostrar/Ocultar Rendimiento de Cartera
          </Button>
        </div>
        <Collapse in={open}>
          <div id="collapse-content">
            {/* ---Rendimiento de cartera Section--- */}
            <div className="text-center mt-5 mb-5">
              <h1 style={{ fontWeight: "bold" }}>
                Resultados de rendimientos individuales
              </h1>
            </div>
            <Card className="mx-3 mb-5" style={{ borderColor: "#050227" }}>
              <Container fluid>
                <div className="mt-5 mb-5 mx-3 row text-center">
                  {/* Select term */}
                  <div className="col-12 col-md-4 mb-3">
                    <div className="row">
                      <CustomTooltip text={tooltips_text.tiempo_inversion}>
                        <label className="label">Plazo de inversión</label>
                      </CustomTooltip>
                    </div>
                    <Select
                      showSearch={true}
                      defaultValue={termd}
                      style={{ width: "100%" }}
                      onChange={handleTermsd}
                    >
                      {termsd.map((e, index) => (
                        <Select.Option key={index} value={e.value}>
                          {e.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>

                  {/* Select principal product */}
                  <div className="col-12 col-md-4 mb-3">
                    <div className="row">
                      <CustomTooltip text={tooltips_text.producto_principal}>
                        <label className="label">
                          Seleccione el Producto Principal
                        </label>
                      </CustomTooltip>
                    </div>
                    <Select
                      mode="default"
                      showSearch
                      placeholder="Seleccionar"
                      value={objective_product_value}
                      style={{ width: "100%" }}
                      onChange={handleObjetiveProduct}
                      filterOption={(input, option) =>
                        option?.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {products.map((e, index) => (
                        <Select.Option key={index} value={e.value}>
                          {e.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>

                  {/* Select coverage products */}
                  <div className="col-12 col-md-4 mb-3">
                    <div className="row">
                      <CustomTooltip text={tooltips_text.productos_cobertura}>
                        <label className="label">
                          Seleccione Producto(s) de Cobertura
                        </label>
                      </CustomTooltip>
                    </div>
                    <Select
                      mode="multiple"
                      showSearch
                      disabled={!objective_product_value}
                      placeholder="Seleccionar"
                      value={coverageProductList}
                      style={{ width: "100%" }}
                      onChange={handleCoverageProducts}
                      filterOption={(input, option) =>
                        option?.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {products.map((e, index) => (
                        <Select.Option key={index} value={e.value}>
                          {e.label}
                        </Select.Option>
                      ))}
                    </Select>
                    {coverageProductList.length === 5 && (
                      <div style={{ color: "red", marginTop: "10px" }}>
                        Solo puede seleccionar hasta 5 productos.
                      </div>
                    )}
                  </div>
                </div>

                <div style={{ height: "60px" }} />

                {/**==================== SECOND SELCTS ROW ================== */}
                <div className="mb-5 mx-3 row text-center">
                  {/* Text Box for Investment Amount */}
                  <div className="col-12 col-md-4 mb-3">
                    <div className="row">
                      <CustomTooltip text={tooltips_text.monto_inversion}>
                        <label className="label">Monto de Inversión</label>
                      </CustomTooltip>
                      <InputGroup className="mb-3">
                        <InputGroup.Text>$</InputGroup.Text>
                        <Form.Control
                          value={investmentAmount}
                          onChange={(e) =>
                            setInvestmentAmount(parseFloat(e.target.value))
                          }
                          placeholder="0.00"
                        />
                      </InputGroup>
                    </div>
                  </div>

                  {/* Cuadro para Porcentaje de Inversión 1 */}
                  <div className="col-12 col-md-4 mb-3">
                    <div className="row">
                      <CustomTooltip text={tooltips_text.porcentaje_producto_objetivo}>
                        <label className="label">
                          Porcentaje de Producto Objectivo
                        </label>
                      </CustomTooltip>
                      <InputGroup className="mb-3">
                        <InputGroup.Text>%</InputGroup.Text>
                        <Form.Control
                          value={objective_investment_percentage}
                          onChange={handleInvestmentPercentage}
                          placeholder="0.00"
                        />
                      </InputGroup>
                    </div>
                  </div>

                  {/* Products Table */}
                  <div className="col-12 col-md-4 mb-3">
                    <div className="row">
                      <CustomTooltip text={tooltips_text.porcentaje_productos_cobertura}>
                        <label className="label">
                          Porcentaje de Productos Cobertura
                        </label>
                      </CustomTooltip>
                    </div>
                    <table className="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <th>Producto</th>
                          <th>Porcentaje de inversión (%)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {coberProducts.map((obj, index) => (
                          <tr key={index}>
                            <td>{obj["product"]}</td>
                            <td>
                              <Input
                                className="form-control-sm text-center"
                                type="number"
                                value={percentages[index]}
                                onChange={(e) =>
                                  handlePorcentageChange(e, index)
                                }
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      {coberProducts.length == 0 ? (
                        <tfoot>
                          <tr>
                            <td colSpan="2">Mostrando 0 Productos</td>
                          </tr>
                        </tfoot>
                      ) : (
                        <tfoot>
                          <tr>
                            <td className="td-total" colSpan="1">
                              Total
                            </td>
                            <td colSpan="2">{totalPercentage + "%"}</td>
                          </tr>
                        </tfoot>
                      )}
                    </table>
                    {totalPercentage !== 100 && (
                      <div style={{ color: "red" }}>
                        *El porcentaje total debe ser igual a 100%.
                      </div>
                    )}
                  </div>
                </div>

                {/* Calculate Performance Button */}
                <div className="mt-5 text-center mb-5">
                  <button
                    onClick={getwalletPerformance}
                    disabled={isButtonDisabled}
                    className="btn btn-lg"
                    style={{
                      backgroundColor: "#050227",
                      color: "white",
                    }}
                  >
                    Calcular Rendimientos
                  </button>
                </div>

                {/* Correlation individual performance table */}
                <div className="mb-5 mx-3 row text-center">
                  {/* Performance Table */}
                  <div className="col-12 col-md-8 mb-3">
                    <div style={{ overflowX: "auto", maxWidth: "100%" }}>
                      <table
                        className="table table-bordered table-striped"
                        style={{ minWidth: "600px" }}
                      >
                        <thead>
                          <tr>
                            <th>Producto</th>
                            <th>Tipo</th>
                            <th>
                              <CustomTooltip text={tooltips_text.porcentaje}>
                                <span>Procentaje %</span>
                              </CustomTooltip>
                            </th>
                            <th>
                              <CustomTooltip text={tooltips_text.correlacion}>
                                <span>Correlación</span>
                              </CustomTooltip>
                            </th>
                            <th>
                              <CustomTooltip text={tooltips_text.rendimiento}>
                                <span>Rendimiento $</span>
                              </CustomTooltip>
                            </th>
                            <th>
                              <CustomTooltip text={tooltips_text.rendimiento}>
                                <span>Desviacion estandar %</span>
                              </CustomTooltip>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {list_products.map((product, index) => (
                            <tr key={index}>
                              <td>{product["PRODUCTO"]}</td>
                              <td>{product["TYPE"]}</td>
                              <td>
                                {product["PERCENTAGE"]
                                  ? product["PERCENTAGE"].toFixed(2)
                                  : "0%"}
                              </td>
                              <td>
                                {product.CORRELACION
                                  ? product.CORRELACION.toFixed(2)
                                  : "0.0"}
                              </td>
                              <td>
                                {product["RENDIMIENTO $"]
                                  ? product["RENDIMIENTO $"].toFixed(2)
                                  : "$0"}
                              </td>
                              <td>
                                {product["DESVIACION ESTANDAR %"]
                                  ? product["DESVIACION ESTANDAR %"].toFixed(2)
                                  : "0%"}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {/* Performance Summary Table */}
                  <div className="col-12 col-md-4 mb-3">
                    <table className="table table-bordered table-striped vertical-table">
                      <thead>
                        <tr>
                          <th>Conceptos</th>
                          <th>Valores</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>
                            <CustomTooltip text={tooltips_text.rendimiento_cartera}>
                              <span>Rendimiento de cartera (USD)</span>
                            </CustomTooltip>
                          </th>
                          <td>
                            {wallet_performance["PORTAFOLIO"][
                              "REND CARTERA ($)"
                            ] !== undefined
                              ? "$" +
                              wallet_performance["PORTAFOLIO"][
                                "REND CARTERA ($)"
                              ].toFixed(2)
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th>
                            <CustomTooltip text={tooltips_text.desviacion_estandar}>
                              <span>Desviación estandar de cartera (%)</span>
                            </CustomTooltip>
                          </th>
                          <td>
                            {wallet_performance["PORTAFOLIO"][
                              "DESVIACION ESTANDAR CARTERA (%)"
                            ] !== undefined
                              ? wallet_performance["PORTAFOLIO"][
                                "DESVIACION ESTANDAR CARTERA (%)"
                              ].toFixed(2)
                              : "N/A"}
                            %
                          </td>
                        </tr>
                        <tr>
                          <th>
                            <CustomTooltip text={tooltips_text.rendimiento_maximo}>
                              <span>Rendimiento máximo (USD)</span>
                            </CustomTooltip>
                          </th>
                          <td>
                            {wallet_performance["PORTAFOLIO"][
                              "RENDIMIENTO MAXIMO"
                            ] !== undefined
                              ? "$" +
                              wallet_performance["PORTAFOLIO"][
                                "RENDIMIENTO MAXIMO"
                              ].toFixed(2)
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                        <th>
                            <CustomTooltip text={tooltips_text.perdida_maxima}>
                              <span>Pérdida máxima (USD)</span>
                            </CustomTooltip>
                          </th>
                          <td>
                            {wallet_performance["PORTAFOLIO"][
                              "RENDIMIENTO MINIMO"
                            ] !== undefined
                              ? "$" +
                              wallet_performance["PORTAFOLIO"][
                                "RENDIMIENTO MINIMO"
                              ].toFixed(2)
                              : "N/A"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* wallet Performance Table */}
                <div className="mb-5 mx-3 row text-center d-flex justify-content-center">
                  <div>
                    {showImage && (
                      <div className="text-center">
                        <img
                          src={decodedImage}
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            display: "inline-block",
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Container>
            </Card>
          </div>
        </Collapse>
      </Container>
    </>
  );
}