//React & General Libraries
import React from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import axios from "axios";
import Plot from "react-plotly.js";

//Bootstrap
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";

//REACT HOOKS
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Select, Input } from "antd";

//ACTIONS
import Loading from "../loading/Loading";
import { setCorrelationProducts } from "../../actions/correlationStudyNxn";
// COMMOM COMPONENTS
import CustomTooltip from "../common/CustomTooltip";
//TIME & PRODUCTS DATA (JSON)
import products_data from "../../data/products.json";
import res_values from "../../data/res_values.json";
import tooltips_text from "../../data/tooltips_text.json";

//IMPORT CSS
import "./CorrelationStudy.css";

//GIFS
import GIF from "../common/Gif";
import phrases_list from "../../data/phrases.json";
import recuerda_gif from "../../gifs/RECUERDA_GIFF_VIDEO.gif";
import sabias_gif from "../../gifs/SABIAS-QUE_GIFF_VIDEO.gif";

export default function CorrelationStudy() {
  //Get ID Token & Username
  const id_token = sessionStorage.getItem("id_token");
  const username = sessionStorage.getItem("username");

  //React HOOKS
  const dispatch = useDispatch();

  // GIF Phrases
  const [randomPhrase, setRandomPhrase] = useState("");
  const [showRecuerda, setShowRecuerda] = useState(true);
  const [showSabias, setShowSabias] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSelects, setIsOpenSelects] = useState(false);
  const [product, setProduct] = useState("Producto");

  //React State Variables for multiple correlation study
  const [valorX, setXValue] = useState(null);
  const [operation_time_hours, setOperationTimeHours] = useState(0);
  const [correlationValue, setCorrelationValue] = useState("");
  const [principalProducts, setPrincipalProducts] = useState([]);

  //second variables
  const [valorX2, setValorX2] = useState(null);
  const [start_date, setStartDate] = useState("");
  const [product1, setProduct1] = useState(null);
  const [product2, setProduct2] = useState(null);
  const [volatility2, setVolatility2] = useState("");
  const [correlationResult, setCorrelationResult] = useState(null);

  const toggleCollapse = () => setIsOpen(!isOpen);
  const toggleSelectsCollapse = () => setIsOpenSelects(!isOpenSelects);
  const [minDate, setMinDate] = useState("");
  const [maxDate, setMaxDate] = useState("");

  useEffect(() => {
    const today = new Date();
    const twoMonthsAgo = new Date(today);
    twoMonthsAgo.setMonth(today.getMonth() - 2);

    // Convertir la fecha al formato YYYY-MM-DD
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    setMaxDate(formatDate(twoMonthsAgo));
  }, []);

  // Actualizar el horario de operación cada vez que cambien product1 o product2
  useEffect(() => {
    if (product1 && product2) {
      const selectedProducts = products_data.products.filter(
        (product) => product.value === product1 || product.value === product2
      );
      const times = selectedProducts.map((product) =>
        parseInt(product.tradeTime)
      );
      const minTime = Math.min(...times);
      setOperationTimeHours(minTime);
    } else {
      setOperationTimeHours("");
    }
  }, [product1, product2]);

  // GIF Recuerda Timer
  useEffect(() => {
    if (showRecuerda) {
      const randomIndex = Math.floor(
        Math.random() * phrases_list.recuerda_phrases.length
      );
      setRandomPhrase(phrases_list.recuerda_phrases[randomIndex]);

      const recuerdaTimer = setTimeout(() => {
        setShowRecuerda(false);
        setTimeout(() => {
          setShowSabias(true);
        }, 120000);
      }, 18000);

      return () => clearTimeout(recuerdaTimer);
    }
  }, [showRecuerda]);

  // GIF Sabias Timer
  useEffect(() => {
    if (showSabias) {
      const randomIndex = Math.floor(
        Math.random() * phrases_list.sabias_phrases.length
      );
      setRandomPhrase(phrases_list.sabias_phrases[randomIndex]);

      const sabiasTimer = setTimeout(() => {
        setShowSabias(false);
        setTimeout(() => {
          const showNext =
            Math.random() < 0.5 ? setShowRecuerda(true) : setShowSabias(true);
        }, 120000);
      }, 18000);

      return () => clearTimeout(sabiasTimer);
    }
  }, [showSabias]);

  //React Store Variables
  const loading = useSelector((state) => state.loading.loadingCorrelation);
  const loadingCorrelationProducts = useSelector(
    (state) => state.loading.loadingCorrelationProducts
  );
  const correlationNXN = useSelector(
    (state) => state.correlationStudyNxn.results
  );

  //Custom variables
  const products = products_data.products;

  //Get correlation product keys
  if (correlationNXN.length > 0) {
    var keys = Array.from(
      new Set([correlationNXN[0]].flatMap((item) => Object.keys(item)))
    );
  }

  //Get correlation Products data
  const getCorrelationProducts = (e) => {
    e.preventDefault();
    const products_label = [];
    principalProducts.forEach(function (e) {
      products_data.products.filter((obj) => {
        if (obj.label == e) {
          products_label.push(obj.value);
        }
      });
    });
    const data = {
      product_list: products_label,
      product_time_operation: [operation_time_hours],
      resolution: valorX,
    };
    dispatch(setCorrelationProducts(data));
  };

  //**********************************************************************handle second correlation variables************************************************** */

  const handleXValueChange = (value) => {
    setValorX2(value);
    console.log("Valor de X seleccionado:", value);
  };

  // Opciones de productos para React-Select
  const productOptions = products_data.products.map((product) => ({
    value: product.value, // Value que se utilizará en product1 y product2
    label: product.label, // Label que se mostrará en el select
  }));

  // Función para manejar la selección de producto 1 y almacenar su objeto completo
  const handleProduct1Change = (selectedOption) => {
    setProduct1(selectedOption);
  };

  // Función para manejar la selección de producto 2 y almacenar su objeto completo
  const handleProduct2Change = (selectedOption) => {
    setProduct2(selectedOption);
  };

  const handleVolatilityChange = (e) => {
    setVolatility2(e.target.value);
    console.log("Volatilidad seleccionada:", e.target.value);
  };

  //Get principal product
  var handlePrincipalProducts = (value) => {
    //Filter operation time from products
    const list_ope_time = [];
    value.forEach(function (e) {
      products_data.products.filter((obj) => {
        if (obj.label == e) {
          list_ope_time.push(Number(String(obj.tradeTime).split(" ")[0]));
        }
      });
    });
    setOperationTimeHours(Math.min(...list_ope_time));
    if (value.length <= 10) {
      setPrincipalProducts(value);
    }
  };

  // Corrleation NxN table colors
  const getColorForValue = (value) => {
    // If the value is between 0.5 and 1, calculate the color according to the yellow to green range
    if (value >= 0.5 && value <= 1) {
      const normalizedValue = (value - 0.5) / 0.5;
      const r = Math.round(255 * (1 - normalizedValue));
      const g = 255;
      const b = 0;
      return rgbToHex(r, g, b);
    }
    // If the value is between 0 and 0.5, calculate the color according to the red to yellow range
    if (value >= 0 && value < 0.5) {
      const normalizedValue = value / 0.5;
      const r = 255;
      const g = Math.round(255 * normalizedValue);
      const b = 0;
      return rgbToHex(r, g, b);
    }
    // If the value is between -0.5 and 0, calculate the color according to the yellow to red range
    if (value >= -0.5 && value < 0) {
      const normalizedValue = Math.abs(value) / 0.5;
      const r = 255;
      const g = Math.round(255 * normalizedValue);
      const b = 0;
      return rgbToHex(r, g, b);
    }
    //If the value is between -1 and -0.5, calculate the color according to the green to yellow range
    if (value >= -1 && value < -0.5) {
      const normalizedValue = (Math.abs(value) - 0.5) / 0.5;
      const r = Math.round(255 * (1 - normalizedValue));
      const g = 255;
      const b = 0;
      return rgbToHex(r, g, b);
    }
    // If the value is out of range, return a neutral color
    return "#FFFFFF";
  };

  // Auxiliary function to convert RGB to hexadecimal
  const componentToHex = (c) => {
    const hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };

  const rgbToHex = (r, g, b) => {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
  };

  // Handle product selection
  const handleProducts = (value) => {
    setProduct_name(value);
    const product = products.find((e) => e.label === value);
    const tradeTime = String(product.tradeTime).split(".");
    if (tradeTime.length > 1) {
      handleOperationTimeHours(`${tradeTime[0]} horas`);
      handleOperationTimeMinutes(
        `${Number("." + tradeTime[1].split(" ")[0]) * 60} minutos`
      );
    } else {
      handleOperationTimeHours(tradeTime[0]);
      handleOperationTimeMinutes("0 minutos");
    }
    setProduct(product.value);
    setTradeType(product.tradeType);
  };

  //Download PDF function
  const handleDownloadPDF = () => {
    const doc = new jsPDF();

    // Add title to the PDF
    doc.text("Tabla de Correlación de Productos", 10, 10);

    // data table
    const data = [];

    // Add the header as the first row
    const headerRow = [""]; // Empty cell for top left corner
    headerRow.push(...keys); // Add column headers
    data.push(headerRow);

    // Add the rest of the rows
    keys.forEach((rowKey) => {
      const rowData = [];
      rowData.push(rowKey); // Add row name as first element
      keys.forEach((colKey) => {
        const value =
          correlationNXN
            .find((item) => item[rowKey])
            ?.[rowKey]?.[colKey]?.toFixed(2) ?? "";
        rowData.push(value); // Add the correlation values ​​for each product pair
      });
      data.push(rowData); // Add entire row to table data
    });

    // Configure table styles
    const styles = {
      lineColor: [0, 0, 0],
      lineWidth: 0.1,
      headStyles: { fillColor: [0, 0, 0], textColor: [255, 255, 255] },
      margin: { top: 20 },
    };

    // Generate the table in the PDF
    doc.autoTable({
      head: [data[0]],
      body: data.slice(1),
      startY: 30,
      theme: "plain",
      styles: styles,
    });

    // Save the PDF file
    doc.save("tabla_correlacion_productos.pdf");
  };

  // Logic to set the value once the function has completed
  useEffect(() => {
    const valorObtenido = correlationNXN[1] || "Por mostrar";
    setCorrelationValue(valorObtenido);
  }, [correlationNXN]);

  // Función para manejar el cálculo de la correlación
  const calculateCorrelation = async () => {
    try {
      // Verificar que todos los campos estén completos antes de enviar la solicitud
      if (!valorX2 || !start_date || !product1 || !product2 || !volatility2) {
        alert("Por favor, completa todos los campos antes de calcular.");
        return;
      }

      // Construir el objeto de datos para enviar al backend
      const requestData = {
        resolution: valorX2,
        start_date: start_date,
        product_1: product1,
        product_2: product2,
        volatility: parseFloat(volatility2),
        product_time_operation: 24,
      };

      console.log("Datos enviados al backend:", requestData);

      // Enviar solicitud al backend
      axios
        .post("/api/correlation_window/", requestData, {
          headers: {
            Authorization: `Bearer ${id_token}`,
            Username: username,
          },
        })
        .then((res) => setCorrelationResult(JSON.parse(res.data)));
    } catch (error) {
      console.error("Error al calcular la correlación:", error);
      alert("Hubo un error al calcular la correlación. Inténtalo de nuevo.");
    }
  };

  //Return JSX
  return (
    <>
      {loading || loadingCorrelationProducts ? <Loading /> : ""}

      {/* First Section */}
      <div className="text-center mt-5 mb-5">
        <h1 style={{ fontWeight: "bold" }}>Estudio de Correlación</h1>
      </div>

      {/* Select information */}

      <Container className="my-4">
        {/* Botón para mostrar u ocultar la sección */}
        <Button
          onClick={toggleCollapse}
          aria-controls="correlation-section-collapse"
          aria-expanded={isOpen}
          className="mb-3 w-100"
          style={{
            backgroundColor: "#050227",
            borderColor: "#050227",
            color: "white",
            fontWeight: 500,
            letterSpacing: "0.1em",
          }}
        >
          {isOpen
            ? "Ocultar Estudio de Correlación con varios productos"
            : "Estudio de Correlación con varios productos"}
        </Button>

        {/* Sección de colapso */}
        <Collapse in={isOpen}>
          <section id="correlation-section-collapse">
            {/* Select information */}
            <Card className="mx-3 mb-5" style={{ borderColor: "#050227" }}>
              <Container fluid>
                <div className="mt-5 mb-5 mx-3 row text-center">
                  {/* Select X resolution */}
                  <div className="col-12 col-md-4 mb-3">
                    <div className="row">
                      <CustomTooltip text={tooltips_text.valor_x}>
                        <label className="label">
                          Selecciona el valor de X
                        </label>
                      </CustomTooltip>
                    </div>
                    <Select
                      showSearch={true}
                      placeholder="Seleccionar"
                      style={{ width: "100%" }}
                      value={valorX}
                      onChange={(value) => setXValue(value)}
                    >
                      {res_values.map((res, index) => (
                        <Select.Option key={index} value={res.value}>
                          {res.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>

                  {/* Select product for the correlation */}
                  <div className="col-12 col-md-4 mb-3">
                    <div className="row">
                      <CustomTooltip text={tooltips_text.productos_correlacion}>
                        <label className="label">
                          Productos de correlación
                        </label>
                      </CustomTooltip>
                    </div>
                    <Select
                      mode="multiple"
                      showSearch={true}
                      placeholder="Seleccionar"
                      value={principalProducts}
                      style={{ width: "100%" }}
                      onChange={handlePrincipalProducts}
                    >
                      {products.map((product, index) => (
                        <Select.Option key={index} value={product.label}>
                          {product.label}
                        </Select.Option>
                      ))}
                    </Select>
                    <h6 style={{ color: "red" }}>
                      *Ingresa únicamente hasta 10 datos
                    </h6>
                  </div>

                  {/* Operation time */}
                  <div className="col-12 col-md-4 mb-3">
                    <div className="row">
                      <CustomTooltip text={tooltips_text.horario_operacion}>
                        <label className="label">Horario de Operación</label>
                      </CustomTooltip>
                    </div>
                    <Input
                      value={operation_time_hours + " horas"}
                      style={{ width: "100%", textAlign: "center" }}
                      disabled={true}
                    ></Input>
                  </div>
                </div>
              </Container>

              {/* Second ROW Execute Correlation NxN Buttons */}
              <div className="mt-2 row text-center">
                <div className="text-center mb-3 col-12">
                  <button
                    onClick={getCorrelationProducts}
                    disabled={!valorX || principalProducts.length < 2}
                    className="btn btn-lg"
                    style={{
                      backgroundColor: "#050227",
                      color: "white",
                    }}
                  >
                    Calcular Correlación
                  </button>
                </div>
              </div>

              {/* Second Container */}
              <Container fluid>
                {/* Print Button and Term Input */}
                <div className="row mx-3">
                  {/* Term */}
                  <div className="col-12 col-md-3 text-start mb-3">
                    <div className="text-center">
                      <CustomTooltip text={tooltips_text.plazo_inversion}>
                        <label className="label">Plazo de inversión</label>
                      </CustomTooltip>
                    </div>
                    <Input
                      className="text-center"
                      type="text"
                      value={correlationValue}
                      disabled={true}
                    ></Input>
                  </div>

                  {/* Download Table Button */}
                  <div className="col-12 col-md-9 text-end mb-3">
                    <button
                      className="btn form-control w-25 w-md-auto text-center"
                      style={{
                        backgroundColor: "#050227",
                        color: "white",
                      }}
                      onClick={handleDownloadPDF}
                    >
                      Imprimir tabla
                    </button>
                  </div>
                </div>

                {/* Selected Products and Correlation Tables */}
                <div className="mt-3 mb-3 mx-3 row text-center">
                  {/* Selected Products Table */}
                  <div className="col-12 col-md-3 mb-3">
                    <table className="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th>
                            <CustomTooltip
                              text={tooltips_text.n_productos_seleccionados}
                            >
                              <span>Productos Seleccionados</span>
                            </CustomTooltip>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {principalProducts.slice(0, 10).map((objeto, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{objeto}</td>
                          </tr>
                        ))}
                      </tbody>
                      {principalProducts.length < 1 ? (
                        <tfoot>
                          <tr>
                            <td colSpan="2">Mostrando 0 Productos</td>
                          </tr>
                        </tfoot>
                      ) : (
                        ""
                      )}
                    </table>
                  </div>

                  {/* Correlation NxN Table */}
                  <div className="col-12 col-md-9">
                    <table className="table table-bordered table-striped">
                      {correlationNXN.length > 0 ? (
                        <>
                          <thead>
                            <tr>
                              <th>Productos</th>
                              {keys.map((key) => (
                                <th key={key}>{key}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {keys.map((rowKey) => (
                              <tr key={rowKey}>
                                <th>{rowKey}</th>
                                {keys.map((colKey) => (
                                  <td
                                    key={colKey}
                                    style={{
                                      backgroundColor: getColorForValue(
                                        correlationNXN.find(
                                          (item) => item[rowKey]
                                        )?.[rowKey]?.[colKey]
                                      ),
                                    }}
                                  >
                                    {correlationNXN
                                      .find((item) => item[rowKey])
                                      ?.[rowKey]?.[colKey]?.toFixed(2) ?? ""}
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </>
                      ) : (
                        <>
                          <thead>
                            <tr>
                              <th>
                                <CustomTooltip
                                  text={tooltips_text.correlacion_productos}
                                >
                                  <th>Correlación de productos</th>
                                </CustomTooltip>
                              </th>
                            </tr>
                          </thead>
                          <tfoot>
                            <tr>
                              <td colSpan="3">
                                Mostrando 0 Correlaciones por el momento
                              </td>
                            </tr>
                          </tfoot>
                        </>
                      )}
                    </table>
                  </div>
                </div>
              </Container>
            </Card>
          </section>
        </Collapse>
      </Container>

      {/**************************************************************second colaps section ************************************************************/}

      <Container className="my-4">
        {/* Botón para mostrar/ocultar el Estudio de Correlación completo */}
        <Button
          onClick={toggleSelectsCollapse}
          aria-controls="selects-collapse"
          aria-expanded={isOpenSelects}
          className="mb-3 w-100"
          style={{
            backgroundColor: "#050227",
            borderColor: "#050227",
            color: "white",
            fontWeight: 500,
          }}
        >
          {isOpenSelects
            ? "Ocultar estudio de correlación entre 2 productos"
            : "Estudio de correlación entre 2 productos"}
        </Button>

        <Collapse in={isOpenSelects}>
          <Card className="mb-5" style={{ borderColor: "#050227", margin: 0 }}>
            <Container fluid>
              <section id="correlation-section-collapse">
                <div
                  id="selects-collapse"
                  className="mt-3 mb-5 row text-center"
                  style={{ margin: 0 }}
                >
                  {/* Select X resolution */}
                  <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                    <div className="row">
                      <CustomTooltip text={tooltips_text.valor_x}>
                        <label className="label">
                          Selecciona el valor de X
                        </label>
                      </CustomTooltip>
                    </div>
                    <Select
                      showSearch={true}
                      placeholder="Seleccionar"
                      style={{ width: "100%" }}
                      value={valorX2}
                      onChange={handleXValueChange}
                      options={res_values}
                    />
                  </div>

                  {/* Start date */}
                  <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                    <div className="row">
                      <CustomTooltip text={tooltips_text.fecha_inicio}>
                        <label className="label">Fecha de inicio</label>
                      </CustomTooltip>
                    </div>
                    <div className="d-flex justify-content-center">
                      <input
                        type="date"
                        value={start_date}
                        onChange={(e) => setStartDate(e.target.value)}
                        min={minDate}
                        max={maxDate}
                        style={{
                          height: "32px",
                        }}
                        className="form-control text-center"
                      />
                    </div>
                  </div>

                  {/* Select para Producto de inversión 1 */}
                  <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                    <div className="row">
                      <CustomTooltip text={tooltips_text.producto_inversion_1}>
                        <label className="label">Producto de inversión 1</label>
                      </CustomTooltip>
                    </div>
                    <Select
                      showSearch={true}
                      placeholder="Seleccionar"
                      style={{ width: "100%" }}
                      value={product1}
                      onChange={handleProduct1Change}
                      options={productOptions}
                    />
                  </div>

                  {/* Select para Producto de inversión 2 */}
                  <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                    <div className="row">
                      <CustomTooltip text={tooltips_text.producto_inversion_2}>
                        <label className="label">Producto de inversión 2</label>
                      </CustomTooltip>
                    </div>
                    <Select
                      showSearch={true}
                      placeholder="Seleccionar"
                      style={{ width: "100%" }}
                      value={product2}
                      onChange={handleProduct2Change}
                      options={productOptions}
                    />
                  </div>

                  {/* Volatility */}
                  <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                    <div className="row">
                      <CustomTooltip text={tooltips_text.volatilidad}>
                        <label className="label">Volatilidad</label>
                      </CustomTooltip>
                    </div>
                    <Input
                      type="number"
                      value={volatility2}
                      onChange={handleVolatilityChange}
                      style={{ width: "100%" }}
                      className="form-control text-center"
                    />
                  </div>

                  {/* Operation time */}
                  <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                    <div className="row">
                      <CustomTooltip text={tooltips_text.horario_operacion}>
                        <label className="label">Horario de Operación</label>
                      </CustomTooltip>
                    </div>
                    <Input
                      value={
                        operation_time_hours
                          ? `${operation_time_hours} horas`
                          : ""
                      }
                      style={{ width: "100%", textAlign: "center" }}
                      disabled={true}
                    />
                  </div>
                </div>
              </section>

              {/* Botón para Calcular Correlación */}
              <div className="mt-2 row text-center">
                <div className="text-center mb-3 col-12">
                  <button
                    className="btn btn-lg"
                    onClick={calculateCorrelation}
                    style={{
                      backgroundColor: "#050227",
                      color: "white",
                    }}
                  >
                    Calcular Correlación
                  </button>
                </div>
              </div>

              {/* correlation image */}
              {correlationResult && (
                <div className="mt-3">
                  <h5>Resultado de la Correlación:</h5>
                  <Plot
                    data={correlationResult.data}
                    layout={{
                      ...correlationResult.layout,
                      width: 1200,
                      height: 600,
                    }}
                  />
                </div>
              )}
            </Container>
          </Card>
        </Collapse>
      </Container>

      {/* Notification GIFs */}
      {showRecuerda && (
        <div className="gif-notification-box">
          <button
            onClick={() => setShowRecuerda(false)}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              background: "transparent",
              border: "none",
              fontSize: "18px",
              cursor: "pointer",
            }}
          >
            &times;
          </button>
          <GIF gif={recuerda_gif} width={"150px"} />
          <p
            style={{
              fontSize: "14px",
              color: "#333",
              margin: 0,
            }}
          >
            {randomPhrase}
          </p>
        </div>
      )}

      {showSabias && (
        <div className="gif-notification-box">
          <button
            onClick={() => setShowSabias(false)}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              background: "transparent",
              border: "none",
              fontSize: "18px",
              cursor: "pointer",
            }}
          >
            &times;
          </button>
          <GIF gif={sabias_gif} width={"150px"} />
          <p
            style={{
              fontSize: "14px",
              color: "#333",
              margin: 0,
            }}
          >
            {randomPhrase}
          </p>
        </div>
      )}
    </>
  );
}
