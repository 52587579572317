import React from "react";
import "./Loading.css";
import loading_gif from "../../gifs/FELICIDADES_GIFF_VIDEO.gif";

export default function CustomLoading() {
  return (
    <div className="centeredGIF">
      <img
        src={loading_gif}
        alt="Loading"
        style={{ width: "20rem", height: "20rem" }}
      />
    </div>
  );
}
