import {
  LOADING_VOLATILITIES,
  LOADING_CHECKLISTS,
  LOADING_LOGIN,
  LOADING_CORRELATION,
  LOADING_CORRELATION_PRODUCTS,
  LOADING_DAILY_REPORT,
} from "./types";

export const loadingVolatilities = (status) => (dispatch) => {
  dispatch({
    type: LOADING_VOLATILITIES,
    payload: status,
  });
};

export const loadingChecklists = (status) => (dispatch) => {
  dispatch({
    type: LOADING_CHECKLISTS,
    payload: status,
  });
};

export const loadingLogin = (status) => (dispatch) => {
  dispatch({
    type: LOADING_LOGIN,
    payload: status,
  });
};

export const loadingCorrelation = (status) => (dispatch) => {
  dispatch({
    type: LOADING_CORRELATION,
    payload: status,
  });
};

export const loadingCorrelationProducts = (status) => (dispatch) => {
  dispatch({
    type: LOADING_CORRELATION_PRODUCTS,
    payload: status,
  });
};

export const loadingDailyReport = (status) => (dispatch) => {
  dispatch({
    type: LOADING_DAILY_REPORT,
    payload: status,
  });
};
