import axios from "axios";
import { INDIVIDUAL_PERFORMANCE } from "./types";
import { loadingCorrelation } from "./loading";

//Get correlation study values
export const individualPerformance = (data) => (dispatch) => {
  //Get Id Token & Username
  const id_token = sessionStorage.getItem("id_token");
  const username = sessionStorage.getItem("username");
  axios
    .post("/api/individual_correlation_study", data, {
      headers: {
        Authorization: `Bearer ${id_token}`,
        Username: username,
      },
    })
    .then(dispatch(loadingCorrelation(true)))
    .then((response) => {
      dispatch({
        type: INDIVIDUAL_PERFORMANCE,
        payload: response.data,
      });
      dispatch(loadingCorrelation(false));
      //DIEGO CAMBIAR ESTO POR EL MODAL DE SUCCESS
      alert("Correlación calculada con éxito");
    })
    .catch((e) => {
      dispatch(loadingCorrelation(false));
      //DIEGO CAMBIAR ESTO POR EL MODAL DE ERROR
      alert("Error en el calculo de Correlación");
    });
};
