import {
  LOADING_CHECKLISTS,
  LOADING_VOLATILITIES,
  LOADING_LOGIN,
  LOADING_CORRELATION,
  LOADING_CORRELATION_PRODUCTS,
  LOADING_DAILY_REPORT
} from "../actions/types";

const initialState = {
  loadingVolatilities: false,
  loadingChecklists: false,
  loadingLogin: false,
  loadingCorrelation: false,
  loadingCorrelationProducts: false,
  loadingDailyReport: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOADING_VOLATILITIES:
      return {
        ...state,
        loadingVolatilities: payload,
      };
    case LOADING_DAILY_REPORT:
      return {
        ...state,
        loadingDailyReport: payload,
      };
    case LOADING_CHECKLISTS:
      return {
        ...state,
        loadingChecklists: payload,
      };
    case LOADING_LOGIN:
      return {
        ...state,
        loadingLogin: payload,
      };
    case LOADING_CORRELATION:
      return {
        ...state,
        loadingCorrelation: payload,
      };
    case LOADING_CORRELATION_PRODUCTS:
      return {
        ...state,
        loadingCorrelationProducts: payload,
      };
    default:
      return state;
  }
}
