import React, { Suspense } from "react";
import { Navigate } from "react-router-dom";

// Importación perezosa (lazy) de los componentes
const Header = React.lazy(() => import("../components/common/Header"));
const Footer = React.lazy(() => import("../components/common/Footer"));
const BreadcrumbD = React.lazy(() =>
  import("../components/common/BreadcrumbD")
);
const DailyIndividualReport = React.lazy(() =>
  import("../components/dailyReport/DailyIndividualReport")
);
import Loading from "../components/loading/Loading";

export default function DailyIndividualReportPage() {
  //Auth and Loading variables
  const isAuthenticated = sessionStorage.getItem("token");

  // If not authenticated, redirect to Login
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <Suspense fallback={<Loading />}>
      <div className="page-container">
        <Header />
        <BreadcrumbD />
        <div className="content-wrap">
          <DailyIndividualReport />
        </div>
        <Footer />
      </div>
    </Suspense>
  );
}
