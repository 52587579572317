import React from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

const CustomTooltip = ({ text, placement = "top", children }) => {
  return (
    <OverlayTrigger
      placement={placement}
      overlay={<Tooltip>{text}</Tooltip>}
    >
      {children}
    </OverlayTrigger>
  );
};

export default CustomTooltip;