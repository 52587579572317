import React, { useState } from "react";

const BlackScholesCalculator = () => {
  // S
  const [spotPrice, setSpotPrice] = useState(0);
  // K
  const [strikePrice, setStrikePrice] = useState(0);
  // T
  const [timeToMaturity, setTimeToMaturity] = useState(0);
  // Unit for time to maturity
  const [timeUnit, setTimeUnit] = useState("años");
  // σ
  const [volatility, setVolatility] = useState(0);
  // r
  const [riskFreeRate, setRiskFreeRate] = useState(0);
  // call or put
  const [optionType, setOptionType] = useState("call");
  const [result, setResult] = useState(null);

  const calculateBlackScholes = () => {
    let T = parseFloat(timeToMaturity);
    if (timeUnit === "días") {
      T = T / 365;
    } else if (timeUnit === "meses") {
      T = T / 12;
    }

    const S = parseFloat(spotPrice);
    const K = parseFloat(strikePrice);
    const sigma = parseFloat(volatility) / 100;
    const r = parseFloat(riskFreeRate) / 100;

    const d1 =
      (Math.log(S / K) + (r + Math.pow(sigma, 2) / 2) * T) /
      (sigma * Math.sqrt(T));
    const d2 = d1 - sigma * Math.sqrt(T);

    const erf = (x) => {
      const sign = x >= 0 ? 1 : -1;
      x = Math.abs(x);
      const a1 = 0.254829592;
      const a2 = -0.284496736;
      const a3 = 1.421413741;
      const a4 = -1.453152027;
      const a5 = 1.061405429;
      const p = 0.3275911;

      const t = 1 / (1 + p * x);
      const y =
        1 -
        ((((a5 * t + a4) * t + a3) * t + a2) * t + a1) * t * Math.exp(-x * x);

      return sign * y;
    };

    const N = (x) => {
      return (1.0 + erf(x / Math.sqrt(2))) / 2.0;
    };

    let optionPrice;
    if (optionType === "call") {
      optionPrice = S * N(d1) - K * Math.exp(-r * T) * N(d2);
    } else if (optionType === "put") {
      optionPrice = K * Math.exp(-r * T) * N(-d2) - S * N(-d1);
    }

    setResult(optionPrice.toFixed(2));
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Calculadora Black-Scholes</h1>
      <p className="mb-4 text-sm text-gray-600">
        Utiliza esta calculadora para determinar el precio teórico de una opción
        usando la fórmula de Black-Scholes. Proporciona los valores a
        continuación.
      </p>
      <div className="grid gap-4">
        <div>
          <strong className="block text-sm font-medium">
            Precio Actual (S)
          </strong>
          <p className="text-xs text-gray-500 mb-1">
            Precio actual del activo subyacente.
          </p>
          <input
            type="number"
            className="w-full p-2 border rounded-full bg-gray-100"
            style={{
              borderRadius: "50px",
              backgroundColor: "rgba(128, 128, 128, 0.2)",
              color: "white",
            }}
            value={spotPrice}
            onChange={(e) => setSpotPrice(e.target.value)}
          />
        </div>
        <div>
          <strong className="block text-sm font-medium">
            Precio de Ejercicio (K)
          </strong>
          <p className="text-xs text-gray-500 mb-1">
            Precio al que se puede ejercer la opción.
          </p>
          <input
            type="number"
            className="w-full p-2 border rounded-full bg-gray-100"
            style={{
              borderRadius: "50px",
              backgroundColor: "rgba(128, 128, 128, 0.2)",
              color: "white",
            }}
            value={strikePrice}
            onChange={(e) => setStrikePrice(e.target.value)}
          />
        </div>
        <div>
          <strong className="block text-sm font-medium">
            Tiempo al Vencimiento (T)
          </strong>
          <p className="text-xs text-gray-500 mb-1">
            Tiempo restante hasta que expire la opción.
          </p>
          <div className="flex items-center gap-2">
            <input
              type="number"
              className="w-full p-2 border rounded-full bg-gray-100"
              style={{
                borderRadius: "50px",
                backgroundColor: "rgba(128, 128, 128, 0.2)",
                color: "white",
              }}
              value={timeToMaturity}
              onChange={(e) => setTimeToMaturity(e.target.value)}
            />
            &nbsp;
            <select
              className="p-2 border rounded-full bg-gray-100"
              style={{
                borderRadius: "50px",
                backgroundColor: "rgba(128, 128, 128, 0.2)",
                color: "white",
              }}
              value={timeUnit}
              onChange={(e) => setTimeUnit(e.target.value)}
            >
              <option value="días">Días</option>
              <option value="meses">Meses</option>
              <option value="años">Años</option>
            </select>
          </div>
        </div>
        <div>
          <strong className="block text-sm font-medium">
            Volatilidad (σ en %)
          </strong>
          <p className="text-xs text-gray-500 mb-1">
            Volatilidad esperada del activo subyacente, como porcentaje.
          </p>
          <input
            type="number"
            className="w-full p-2 border rounded-full bg-gray-100"
            style={{
              borderRadius: "50px",
              backgroundColor: "rgba(128, 128, 128, 0.2)",
              color: "white",
            }}
            value={volatility}
            onChange={(e) => setVolatility(e.target.value)}
          />
        </div>
        <div>
          <strong className="block text-sm font-medium">
            Tasa Libre de Riesgo (r en %)
          </strong>
          <p className="text-xs text-gray-500 mb-1">
            Tasa de interés libre de riesgo, como porcentaje.
          </p>
          <input
            type="number"
            className="w-full p-2 border rounded-full bg-gray-100"
            style={{
              borderRadius: "50px",
              backgroundColor: "rgba(128, 128, 128, 0.2)",
              color: "white",
            }}
            value={riskFreeRate}
            onChange={(e) => setRiskFreeRate(e.target.value)}
          />
        </div>
        <div>
          <strong className="block text-sm font-medium">Tipo de Opción</strong>
          <p className="text-xs text-gray-500 mb-1">
            Selecciona "Call" para una opción de compra o "Put" para una opción
            de venta.
          </p>
          <select
            className="w-full p-2 border rounded-full bg-gray-100"
            style={{
              borderRadius: "50px",
              backgroundColor: "rgba(128, 128, 128, 0.2)",
              color: "white",
            }}
            value={optionType}
            onChange={(e) => setOptionType(e.target.value)}
          >
            <option value="call">Call</option>
            <option value="put">Put</option>
          </select>
        </div>
      </div>
      <div className="flex text-center mt-4 mb-4">
        <button
          className="btn btn-md"
          style={{
            backgroundColor: "#050227",
            color: "white",
            width: "100%",
            maxWidth: "300px",
            borderRadius: "50px",
          }}
          onClick={calculateBlackScholes}
        >
          Calcular
        </button>
      </div>

      {result !== null && (
        <div className="mt-6 p-6 bg-green-200 text-green-800 rounded-lg text-center text-lg font-bold">
          <strong>Precio de la Opción:</strong> ${result}
        </div>
      )}
    </div>
  );
};

export default BlackScholesCalculator;
