import { INDIVIDUAL_PERFORMANCE } from "../actions/types";

const initialState = {
  results: [
    {
      AAPL: {
        PORTAFOLIO: {
          PRODUCTO: "por calcular",
          TYPE: "por calcular",
          PERCENTAGE: 0,
          "RENDIMIENTO %": 0,
          "RENDIMIENTO $": 0,
          "DESVIACION ESTANDAR %": 0,
          "INTERVALO DE CONFIANZA": "Por calcular",
          CORRELACION: 0,
        },
      },
    },
  ],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case INDIVIDUAL_PERFORMANCE:
      return {
        ...state,
        results: payload,
      };
    default:
      return state;
  }
}
