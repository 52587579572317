import React, { Suspense } from "react";
import { Navigate } from "react-router-dom";

import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import Breadcrum from "../components/common/Breadcrum";
import Loading from "../components/loading/Loading";

const AnalysisSumary = React.lazy(() =>
  import("../components/analysisSummary/AnalysisSummary")
);

export default function AnalysisSummaryPage() {
  //Auth and Loading variables
  const isAuthenticated = sessionStorage.getItem("token");

  // If is not Athenticate redirect to Login
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <Suspense fallback={<Loading />}>
      <Header />
      <Breadcrum AnalysisSumary="show" individualAnalysis="none" />
      <AnalysisSumary />
      <Footer />
    </Suspense>
  );
}
