import React, { Suspense } from "react";
import { Navigate } from "react-router-dom";

// Importación perezosa (lazy) de los componentes
const GroupAnalysis = React.lazy(() =>
  import("../components/groupAnalysis/GroupAnalysis")
);
const Header = React.lazy(() => import("../components/common/Header"));
const Footer = React.lazy(() => import("../components/common/Footer"));
const BreadcrumbG = React.lazy(() =>
  import("../components/common/BreadcrumbG")
);
import Loading from "../components/loading/Loading";

export default function GroupAnalysisPage() {
  //Auth and Loading variables
  const isAuthenticated = sessionStorage.getItem("token");

  // If not authenticated, redirect to Login
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <Suspense fallback={<Loading />}>
      <div id="historic_page_container">
        <Header />
        <BreadcrumbG />
        <GroupAnalysis />
        <Footer />
      </div>
    </Suspense>
  );
}
